import React from 'react';
import cn from 'classnames';
import { Trans } from '@lingui/macro';
import Script from 'next/script'

import styles from './Footer.module.scss';
import Link from './Link';
import { aboutUrl } from '../utils/urls';

const Footer = ({ locale }) => (
    <footer className={styles.footer}>
        <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                window.twttr = (function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0],
                    t = window.twttr || {};
                if (d.getElementById(id)) return t;
                js = d.createElement(s);
                js.id = id;
                js.src = "https://platform.twitter.com/widgets.js";
                fjs.parentNode.insertBefore(js, fjs);

                t._e = [];
                t.ready = function(f) {
                    t._e.push(f);
                };
                return t;
                }(document, "script", "twitter-wjs"));
        `,
            }}
        />
        <div className={cn('container', styles.inner)}>
            <div className="row">
                <div className="col-12 col-md-4 order-12 order-md-1">
                    <p className="h3 d-none d-md-block">
                        <Trans id="brand" />
                    </p>
                    <p className="text-white-50 d-none d-md-block">
                        <Trans id="index.blockTitle.feature.sub" />
                    </p>
                    &copy; Bike Visualization. All rights reserved
                </div>
                <div className="col-12 col-md-8 order-1 order-md-12">
                    <ul className={styles.copyright}>
                        <li>
                            <Link href={aboutUrl()}>
                                <a>
                                    <Trans id="footer.about" />
                                </a>
                            </Link>
                        </li>
                        <li>
                            <a href="mailto:contact@visualization.bike">
                                <Trans id="footer.contact" />
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/bike_visual" className="twitter-follow-button" data-show-count="false">
                                Follow @bike_visual
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
